import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  margin: 0 0 2.5em;
  padding: 0 ${props => props.theme.sideSpace.contentLarge};
  text-align: center;
  color: ${props => props.theme.colors.blackLight};
  @media screen and (max-width: ${props => props.theme.responsive.small}) {
    padding: 0 ${props => props.theme.sideSpace.contentSmall};
  }
`;

const ByMeCoffee = () => {
  return (
    <Wrapper>
      <a
        href="https://www.buymeacoffee.com/nojiri1098"
        target="_blank"
        rel="noreferrer"
      >
        <img
          src="https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png"
          alt="Buy Me A Coffee"
          style={{ height: "40px" }}
        />
      </a>
    </Wrapper>
  );
};

export default ByMeCoffee;
